<div class="w-full h-full bg-white gap-3 flex flex-col">
  <div class="w-full">
    Please select a connection type:
  </div>
  <div class="w-full mb-3">
    <p-dropdown [options]="connectionOptions" [(ngModel)]="selectedOption" styleClass="w-full" [pTooltip]="connectionOptions.length === 1 ? 'Only \'' + selectedOption?.name + '\' option is supported.' : undefined"
                optionLabel="name" placeholder="Select an option" appendTo="body" [disabled]="connectionOptions.length === 1"
                (onChange)="selectedOptionChanged()"
    ></p-dropdown>
  </div>
  @if (selectedConnectorMeta?.description) {
    <div class="w-full mb-3 flex gap-2 text-blue-500">
      <mat-icon>error</mat-icon>
      <label class="connector-description" [innerHTML]="selectedConnectorMeta?.description"></label>
    </div>
  }

  @if (selectedOption) {
    <div class="w-full grid grid-cols-4 gap-3 mb-3 items-center">
      @if (selectedOption.key !== ConnectorType.OAUTH_2) {
        <label class="col-span-full">
          Please fill out all required fields:
        </label>
      }

      @if (selectedOption.key === ConnectorType.OAUTH_2) {
        <p-button class="col-span-full" styleClass="w-full" (click)="save()" label="Connect">
          <mat-icon>encrypted</mat-icon>
        </p-button>
      } @else {
        @for (requiredParam of selectedConnectorMeta?.requiredParameters; track requiredParam.name) {
          <label [for]="'connection-' + requiredParam.name" class="required-input-label">{{ requiredParam.title }}</label>
          @if (requiredParam.type === 'text' || requiredParam.type === 'url' || requiredParam.type === 'email' || requiredParam.type === 'password') {
            <input [id]="'connection-' + requiredParam.name" type="text" [name]="requiredParam.name" [type]="requiredParam.type" pInputText
                   [(ngModel)]="connectionConfig[requiredParam.name]" class="col-span-3" [placeholder]="requiredParam.placeholder"/>
          } @else if (requiredParam.type === 'paragraph') {
            <textarea [id]="'connection-' + requiredParam.name" rows="5" [name]="requiredParam.name" pInputTextarea class="col-span-3"
                      [(ngModel)]="connectionConfig[requiredParam.name]" [autoResize]="true" [placeholder]="requiredParam.placeholder"></textarea>
          }
        }
      }
    </div>

    @if (selectedOption.key !== ConnectorType.OAUTH_2) {
      <div class="w-full flex justify-end gap-3">
        <p-button severity="secondary" (click)="ref.close()" label="Cancel">
          <mat-icon>close</mat-icon>
        </p-button>
        <p-button severity="primary" (click)="save()" label="Save">
          <mat-icon>done</mat-icon>
        </p-button>
      </div>
    }
  }

</div>
