import { Component, Inject, Input, OnInit } from '@angular/core';
import { IntegrationStatusType } from '@core/data/type/integration-status.type';
import { Platform } from '@core/data/model/platform';
import { ConfirmationService, MenuItem, Message, MessageService } from 'primeng/api';
import {
  isApmPlatform,
  isCicdPlatform,
  isItsmPlatform,
  isScmPlatform,
  isTaskPlatform,
  PlatformTypeMeta,
  platformTypeMetas
} from '@core/data/type/platform.type';
import { EMPTY } from 'rxjs';
import { IntegrationService } from '@core/data/service/integration.service';
import { catchError } from 'rxjs/operators';
import { WINDOW } from '@shared/window';
import { PlatformHolder } from '@modules/settings/integrations/interface/platform-holder';
import { ConnectorType } from '@core/data/type/connector.type';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import {
  ConnectionOptionsComponent
} from '@modules/settings/integrations/components/connection-options/connection-options.component';
import {
  ConnectionConfigHolder,
  ConnectionOptionHolder
} from '@modules/settings/integrations/interface/connection-option-holder';
import { PlatformConnectionDto } from '@core/data/model/dto/platform-connection.dto';
import { Router } from '@angular/router';

@Component({
  selector: 'app-integration-box',
  templateUrl: './integration-box.component.html',
  styleUrl: './integration-box.component.scss'
})
export class IntegrationBoxComponent implements OnInit {
  IntegrationStatusType = IntegrationStatusType;

  @Input()
  platformHolder!: PlatformHolder;

  @Input()
  status = IntegrationStatusType.DISCONNECTED;

  @Input()
  active = false;

  menuItems: MenuItem[] = [];
  platformTypeMeta!: PlatformTypeMeta;
  loading = false;
  title = '';
  platform!: Platform;
  ref?: DynamicDialogRef;

  constructor(
    protected router: Router,
    private confirmationService: ConfirmationService,
    private dialogService: DialogService,
    private messageService: MessageService,
    private integrationService: IntegrationService,
    @Inject(WINDOW) private window: Window
  ) {
  }

  ngOnInit() {
    this.platform = this.platformHolder.platform;

    let showActionName;
    let showActionIcon;
    if (isScmPlatform(this.platform.type)) {
      showActionName = 'Show Repositories';
      showActionIcon = 'folder_data';
    } else if (isTaskPlatform(this.platform.type) || isCicdPlatform(this.platform.type)) {
      showActionName = 'Show Projects';
      showActionIcon = 'tactic';
    } else if (isItsmPlatform(this.platform.type)) {
      showActionName = 'Show Groups';
      showActionIcon = 'groups';
    } else if (isApmPlatform(this.platform.type)) {
      showActionName = 'Show Applications';
      showActionIcon = 'apps';
    } else {
      showActionName = 'Show';
      showActionIcon = 'search';
    }

    this.platformTypeMeta = platformTypeMetas()[this.platform.type];
    this.title = this.platformTypeMeta.title;

    this.menuItems = [
      {
        label: showActionName,
        icon: showActionIcon,
        routerLink: this.platform.type.toLocaleLowerCase('en-US')
      },
      {
        label: 'Update Connection',
        icon: 'edit',
        command: () => {
          this.showConnectionOptionsDialog();
        }
      },
      {
        label: 'Disconnect',
        icon: 'power_off',
        command: () => {
          this.showDisconnectConfirmation();
        }
      }
    ];
  }

  buttonTitleByStatus() {
    if (this.loading) {
      return 'Connecting...';
    }
    switch (this.status) {
      case IntegrationStatusType.NOT_CONNECTED:
      case IntegrationStatusType.FAILED_TO_CONNECT:
        return 'Connect';
      case IntegrationStatusType.CONNECTED:
        return 'Connected';
      case IntegrationStatusType.PENDING:
        return 'Connecting...';
      case IntegrationStatusType.PENDING_DISCONNECT:
        return 'Disconnecting...';
      default:
        return 'Connect';
    }
  }

  buttonEnabledByStatus() {
    if (this.loading) {
      return false;
    }
    switch (this.status) {
      case IntegrationStatusType.CONNECTED:
      case IntegrationStatusType.PENDING_DISCONNECT:
        return false;
      default:
        return true;
    }
  }

  severityByStatus() {
    if (this.loading) {
      return 'warning';
    }
    switch (this.status) {
      case IntegrationStatusType.NOT_CONNECTED:
        return 'primary';
      case IntegrationStatusType.CONNECTED:
        return 'success';
      case IntegrationStatusType.FAILED_TO_CONNECT:
      case IntegrationStatusType.PENDING:
      case IntegrationStatusType.PENDING_DISCONNECT:
        return 'warning';
      default:
        return 'secondary';
    }
  }

  iconByStatus() {
    switch (this.status) {
      case IntegrationStatusType.NOT_CONNECTED:
        return 'power';
      case IntegrationStatusType.CONNECTED:
        return 'verified';
      case IntegrationStatusType.PENDING:
      case IntegrationStatusType.PENDING_DISCONNECT:
        return 'hourglass_top';
      case IntegrationStatusType.FAILED_TO_CONNECT:
        return 'sync_problem';
      default:
        return undefined;
    }
  }

  tooltipByStatus() {
    if (this.loading) {
      return 'The connection is being created. It may take a few minutes to complete.';
    }
    switch (this.status) {
      case IntegrationStatusType.PENDING:
        return 'The connection is being created. It may take a few minutes to complete. Please click to retry şf you think it took longer than the expected!';
      case IntegrationStatusType.FAILED_TO_CONNECT:
        return 'The connection couldn\'t be established due to unauthorized access. Please click to retry to connect!';
      case IntegrationStatusType.PENDING_DISCONNECT:
        return 'The integration is being disconnected. It may take a few minutes to complete.';
      default:
        return undefined;
    }
  }

  showConnectionOptionsDialog() {
    const connectorTypes = this.platform.connectors;
    if (connectorTypes.length === 1 && connectorTypes[0] === ConnectorType.OAUTH_2) {
      this.doConnection(connectorTypes[0]);
      return;
    }

    this.ref = this.dialogService.open(ConnectionOptionsComponent, {
      width: '35vw',
      header: 'Connection Options',
      data: {
        platform: this.platform
      }
    });
    this.ref.onClose.subscribe((selectedConnectionOption: ConnectionOptionHolder) => {
      if (selectedConnectionOption) {
        this.doConnection(selectedConnectionOption.connectorType, selectedConnectionOption.config)
      }
    });
  }

  doConnection(connectorType: ConnectorType, config?: ConnectionConfigHolder) {
    if (connectorType === ConnectorType.OAUTH_2) {
      this.confirmationService.confirm({
        header: `Connect to ${ this.title }`,
        message: `You will be redirecting to ${ this.title } website for authentication. Please make sure you authenticate personal and organization accounts that you would like integrate.`,
        acceptLabel: 'Connect',
        rejectLabel: 'Cancel',
        acceptButtonStyleClass: 'p-button-success',
        rejectButtonStyleClass: 'p-button-secondary mr-4',
        accept: () => {
          this.connectOauth2();
        }
      });
    } else {
      this.connect(connectorType, config);
    }
  }

  showDisconnectConfirmation() {
    this.confirmationService.confirm({
      header: `Disconnect from ${ this.title }`,
      message: 'The integration will be disconnected.',
      acceptLabel: 'OK',
      rejectLabel: 'Cancel',
      acceptButtonStyleClass: 'p-button-secondary',
      rejectButtonStyleClass: 'p-button-primary mr-4',
      accept: () => {
        this.disconnect();
      }
    });
  }

  connectOauth2() {
    this.loading = true;
    this.integrationService.initOauth2Integration(this.platform, false)
      .pipe(
        catchError(() => {
          this.loading = false;
          return EMPTY;
        })
      )
      .subscribe(integrationInit => {
        this.window.location = integrationInit.oauth2AuthorizationUrl;
      });
  }

  connect(connectorType: ConnectorType, config?: ConnectionConfigHolder) {
    // set any credential if exists
    const platformConnection = { ...config } as PlatformConnectionDto;
    platformConnection.connectorType = connectorType;

    this.loading = true;
    this.integrationService.createConnection(this.platform.type, platformConnection)
      .pipe(
        catchError(() => {
          this.loading = false;
          return EMPTY;
        })
      ).subscribe(orgPlatform => {
        const message: Message = {
          severity: 'success',
          summary: 'Congrats!',
          detail: 'Integration was successfully established.'
        };
        this.messageService.add(message);
        this.platformHolder.status = orgPlatform.status;
        this.loading = false;

        this.router.navigate([`settings/integrations/${this.platformTypeMeta.slug}`]);
      });
  }

  disconnect() {
    this.loading = true;

    this.integrationService.disconnectConnection(this.platform)
      .pipe(
        catchError(() => {
          this.loading = false;
          return EMPTY;
        })
      )
      .subscribe(orgPlatform => {
        this.loading = false;
        this.platformHolder.status = orgPlatform.status;
        const message: Message = {
          severity: 'warning',
          summary: 'Done!',
          detail: 'The integration was queued to be disconnected.'
        };

        this.messageService.add(message);
      });
  }
}
