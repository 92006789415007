import { Component } from '@angular/core';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ConnectorType, ConnectorTypeMeta } from '@core/data/type/connector.type';
import {
  ConnectionConfigHolder,
  ConnectionOptionHolder
} from '@modules/settings/integrations/interface/connection-option-holder';
import { PlatformTypeMeta, platformTypeMetas } from '@core/data/type/platform.type';
import { Platform } from '@core/data/model/platform';

@Component({
  selector: 'app-connection-options',
  templateUrl: './connection-options.component.html',
  styleUrl: './connection-options.component.scss'
})
export class ConnectionOptionsComponent {
  ConnectorType = ConnectorType;

  connectionOptions: { key: ConnectorType, name: string }[] = [];
  selectedOption?: { key: ConnectorType, name: string };
  selectedConnectorType?: ConnectorType;
  selectedConnectorMeta?: ConnectorTypeMeta;
  connectionConfig: { [key: string]: string } = { };

  platformTypeMeta!: PlatformTypeMeta;

  constructor(
    private dialogService: DialogService,
    private dialogConfig: DynamicDialogConfig,
    public ref: DynamicDialogRef
  ) {
    const platformMetas = platformTypeMetas();

    const platform = this.dialogConfig!.data!.platform as Platform;
    this.platformTypeMeta = platformMetas[platform.type];

    this.connectionOptions = platform.connectors
      // SSH connection is not configurable by user - as of now!
      .filter(connectorType => connectorType !== ConnectorType.SSH)
      .map(connectorType => ({
        key: connectorType,
        name: this.platformTypeMeta.connectorMetas[connectorType].title
      }));

    if (this.connectionOptions.length === 1) {
      this.selectedOption = this.connectionOptions[0];
      this.selectedOptionChanged();
    }
  }

  selectedOptionChanged() {
    if (this.selectedOption) {
      this.selectedConnectorType = this.selectedOption?.key;
      this.selectedConnectorMeta = this.platformTypeMeta.connectorMetas[this.selectedConnectorType!]
    }
  }

  save() {
    const selectedConnectionHolder = {
      connectorType: this.selectedOption?.key,
      config: this.connectionConfig as ConnectionConfigHolder
    } as ConnectionOptionHolder;
    this.ref.close(selectedConnectionHolder);
  }

  protected readonly Object = Object;
}
