import { Component, Inject, OnInit } from '@angular/core';
import { IntegrationService } from '@core/data/service/integration.service';
import { WINDOW } from '@shared/window';
import { PlatformTypeMeta, platformTypeMetas } from '@core/data/type/platform.type';
import { forkJoin } from 'rxjs';
import { tap } from 'rxjs/operators';
import { OrganizationPlatform } from '@core/data/model/organization-platform';
import { IntegrationStatusType } from '@core/data/type/integration-status.type';
import { Platform } from '@core/data/model/platform';
import { PlatformHolder } from '@modules/settings/integrations/interface/platform-holder';
import { TitleService } from '@core/services/title.service';

@Component({
  selector: 'app-integrations',
  templateUrl: './integrations.component.html',
  styleUrl: './integrations.component.scss'
})
export class IntegrationsComponent implements OnInit {

  platformHolders: PlatformHolder[] = [];
  platformTypeMetas: { [key: string]: PlatformTypeMeta } = {};
  availablePlatforms: Platform[] = [];
  organizationPlatforms: OrganizationPlatform[] = [];

  constructor(
    @Inject(WINDOW) private window: Window,
    private integrationService: IntegrationService,
    private titleService: TitleService
  ) {
    this.titleService.resource = 'Integrations';
  }

  ngOnInit() {
    this.platformTypeMetas = platformTypeMetas();
    this.initPage();
  }

  initPage(): void {
    this.titleService.loading = true;
    forkJoin([
      this.integrationService.findAllPlatforms().pipe(
        tap(platforms => this.availablePlatforms = platforms)
      ),
      this.integrationService.findAllOrganizationPlatforms().pipe(
        tap(organizationPlatforms => this.organizationPlatforms = organizationPlatforms)
      )
    ]).subscribe(() => {
        this.platformHolders = [];

        this.organizationPlatforms.forEach(orgPlatform => {
          const platform = this.availablePlatforms.find(platform => platform.type === orgPlatform.platformType);
          if (platform) {
            this.platformHolders.push({
              platform: platform,
              status: orgPlatform.status
            });
          }
        })

        // Find platforms that organization hasn't integrated yet
        this.availablePlatforms.filter(platform => this.organizationPlatforms
          .findIndex(orgPlatform => orgPlatform.platformType === platform.type) === -1
        ).forEach(platform => {
          this.platformHolders.push({
            platform: platform,
            status: IntegrationStatusType.NOT_CONNECTED
          });
        });
        this.titleService.loading = false;
      }
    );
  }
}
