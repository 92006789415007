<div class="w-[268px] h-[179px] px-5 py-4 bg-white rounded-lg border border-slate-200 flex-col justify-center items-start gap-3 inline-flex">
  <div class="self-stretch h-[90px] flex-col justify-start items-start gap-2 flex"
       [style.cursor]="status === IntegrationStatusType.CONNECTED ? 'pointer' : 'default'"
       [routerLink]="status === IntegrationStatusType.CONNECTED ? this.platformTypeMeta.slug : null">
    <div class="self-stretch justify-between items-start inline-flex">
      <img [src]="'assets/img/platform_' + platform.type + '.svg'" [alt]="platform.type">
      <!-- <p-inputSwitch *ngIf="status === IntegrationStatusType.CONNECTED" [(ngModel)]="active"></p-inputSwitch> -->
    </div>
    <div class="self-stretch h-[46px] flex-col justify-start items-start gap-1 flex">
      <div class="self-stretch text-zinc-800 text-xl font-medium leading-normal">{{ title }}</div>
    </div>
  </div>

  <div class="self-stretch h-px flex-col justify-center items-start flex">
    <div class="self-stretch h-[0px] border border-slate-200"></div>
  </div>

  <div class="self-stretch h-8 flex-row justify-between items-center flex">
    <p-button
      [label]="buttonTitleByStatus()" class="font-medium connect-button" [outlined]="true" [severity]="severityByStatus()"
      [disabled]="!buttonEnabledByStatus()" [pTooltip]="tooltipByStatus()" [loading]="loading"
      (click)="showConnectionOptionsDialog()"
    >
      <mat-icon *ngIf="!loading">{{ iconByStatus() }}</mat-icon>
    </p-button>


    @if (status === IntegrationStatusType.CONNECTED) {
      <p-button icon="pi" class="ml-auto" [rounded]="true" [text]="true" severity="secondary"
                (click)="menu.toggle($event)" [disabled]="loading">
        <mat-icon>expand_more</mat-icon>
      </p-button>
      <p-menu #menu [model]="menuItems" [popup]="true">
        <ng-template pTemplate="item" let-item>
          <a [attr.tabindex]="-1" class="p-menuitem-link flex justify-between items-center	 p-3 gap-3 text-sm"
             [routerLink]="item.routerLink">
            <mat-icon>{{ item.icon }}</mat-icon>
            <span> {{ item.label }}</span>
          </a>
        </ng-template>
      </p-menu>
    }
  </div>
</div>
